import { Device, Field, LocationType, MeasurementName, MeasurementTransformations } from '@agdir/domain';
import { Component, contentChildren, input, Input, model, output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { bbox, featureCollection, multiPolygon, point, polygon } from '@turf/turf';
import { Feature, FeatureCollection, MultiPolygon, Point, Polygon } from 'geojson';
import { FillPaint, LinePaint, LngLatBoundsLike, Map, MapMouseEvent } from 'mapbox-gl';
import { BehaviorSubject, combineLatest, from, merge, Observable, of, ReplaySubject } from 'rxjs';
import { map, switchAll, switchMap } from 'rxjs/operators';
import { DigifarmService } from '../digifarms/digifarms.service';
import {
	calcBounds,
	DEFAULT_FITBOUND_LOCATION,
	DEFAULT_FITBOUNDS_OPTIONS,
	DEFAULT_MAP_CONFIG,
	LocationMapSettings,
	LocationMergeResult,
	LocationSplitResult,
	makeMapConfig,
} from '../utils';
import { MapPolygonComponent } from './map-polygon.component';

const SELECTED_POLYGON_COLOR = '#98cd78';

@Component({
	selector: 'agdir-map',
	templateUrl: './map.component.html',
	styleUrl: './map.component.scss',
	standalone: false,
})
export class AgdirMapComponent {
	newMarker = model<Point>();
	showZoningLayers = input(false);
	showFullscreen = input(false);
	setMarkerOnClick = input(false);
	disableDefaultUI = input(false);
	fillColor = input('#744f29');
	displayLocationNames = input(false);
	showLocateMeControl = input(false);
	showGeocoderControl = input(false);
	showDrawingTools = input(false);
	showSplitMergeTools = input(false);
	showDetectFieldsControl = input(false);
	fieldSelectionMode = model(false);
	drawingMode = model(false);
	editMode = input(false);
	boundToDevice = input(false);
	hideToolbar = input(false);

	loading = output<boolean>();
	newMarkerPosition = output<Point | undefined>();
	locationSplitted = output<LocationSplitResult>();
	locationMerged = output<LocationMergeResult>();
	locationDrawn = output<Feature<MultiPolygon>>();
	locationUpdated = output<Feature<MultiPolygon>>();
	fieldsDetected = output<FeatureCollection<Polygon> | null>();
	objectClick = output<[Field | Device, Feature<MultiPolygon> | null]>();
	mapReady = output<Map>();
	projectedPolygons = contentChildren(MapPolygonComponent);

	get hasAnyZone() {
		return this.unfilteredLocations.some((s) => s.locationType === LocationType.fieldZone);
	}

	unfilteredLocations: Field[] = [];

	locations$ = new ReplaySubject<Field[]>(1);
	devices$ = new BehaviorSubject<Device[]>([]);
	myPositionTrigger$ = new ReplaySubject<Point>(1);

	myPosition$ = merge([
		this.myPositionTrigger$,
		from(
			new Promise<Point>((resolve) =>
				this.window.navigator.geolocation.getCurrentPosition((position) =>
					resolve({ type: 'Point', coordinates: [position.coords.longitude, position.coords.latitude] }),
				),
			),
		),
	]).pipe(switchAll());

	locationMap$: Observable<LocationMapSettings> = combineLatest([this.locations$, this.devices$]).pipe(
		switchMap(([locations, devices]) => {
			const boundToDevice = this.boundToDevice() && !!devices.at(0)?.geoPoint;

			if (!boundToDevice && locations?.length > 0 && locations[0]?.geoJson) {
				return of(locations.map((s) => s.geoJson!));
			}
			return this.getBrowserGeoLocation(this.newMarker() || devices.at(0)?.geoPoint);
		}),
		map((g) => makeMapConfig(g as MultiPolygon[])),
	);
	mapInstance?: Map;
	private selectedPolygon?: Feature<MultiPolygon>;

	constructor(
		private matDialog: MatDialog,
		private window: Window,
	) {}

	@Input() set locations(locations: Field[]) {
		this.unfilteredLocations = locations?.filter((s) => !!s) || [];
		this.locations$.next(this.unfilteredLocations);
	}

	@Input() set devices(devices: Device[]) {
		this.devices$.next(devices?.filter((device) => device?.geoPoint) ?? []);
	}

	onMapReady(map: Map) {
		this.mapInstance = map;
		this.mapReady.emit(map);
		if (this.projectedPolygons() && this.projectedPolygons().length > 0) {
			const polygonsToFit = this.projectedPolygons().map((s) => s.feature()!.geometry as MultiPolygon);
			this.mapInstance?.fitBounds(calcBounds(polygonsToFit), {
				...DEFAULT_FITBOUNDS_OPTIONS,
				zoom: DEFAULT_MAP_CONFIG.zoom,
				maxZoom: DEFAULT_MAP_CONFIG.maxZoom,
			});
		}
	}

	locationZoneFilterChange(showFieldZones: boolean) {
		this.locations$.next(this.unfilteredLocations.filter((s) => showFieldZones || s.locationType !== LocationType.fieldZone));
	}

	mainLocationFilterChange(showMainFields: boolean) {
		this.locations$.next(this.unfilteredLocations.filter((s) => showMainFields || s.locationType !== LocationType.field));
	}

	locationFillProps(location: Field): FillPaint {
		return {
			'fill-color': location.locationType === LocationType.fieldZone ? location.color || '#e5d989' : location.color || this.fillColor(),
			'fill-opacity': location.locationType === LocationType.fieldZone ? 0.5 : 0.8,
		};
	}

	locationLineProps(location: Field): LinePaint {
		return {
			'line-color': '#ffffff',
			'line-width': location.locationType === LocationType.fieldZone ? 2 : 3,
			'line-dasharray': location.locationType === LocationType.fieldZone ? [1, 2] : [1],
		};
	}

	projectedFillPaint(projected: MapPolygonComponent): FillPaint {
		return { 'fill-color': 'white', 'fill-opacity': 0.8, ...projected.fillPaint() };
	}

	projectedOutlinePaint(projected: MapPolygonComponent): LinePaint {
		return { 'line-color': '#ffffff', 'line-width': 3, ...projected.outlinePaint() };
	}

	projectedSymbolPaint(projected: MapPolygonComponent) {
		const baseSymbolFill = {
			'text-field': projected.name(),
			...projected.symbolPaint(),
		};

		if (!projected.deletable()) {
			return baseSymbolFill;
		}
		return { 'icon-image': 'delete-icon', 'icon-size': 0.05, 'icon-offset': [0, 450], ...baseSymbolFill };
	}

	onGeoLocate($event: Point) {
		this.mapInstance?.fitBounds(
			bbox(featureCollection(Array(2).fill(point([$event?.coordinates[0], $event?.coordinates[1]])))) as LngLatBoundsLike,
			{
				...DEFAULT_FITBOUNDS_OPTIONS,
				zoom: DEFAULT_MAP_CONFIG.zoom,
				maxZoom: DEFAULT_MAP_CONFIG.maxZoom,
			},
		);

		this.myPositionTrigger$.next($event);
	}

	showZones(show: boolean) {
		if (this.mapInstance) {
			if (show) {
				DigifarmService.addZoningLayers(this.mapInstance);
			} else {
				DigifarmService.removeZoningLayers(this.mapInstance);
			}
		}
	}

	onLocationSplitted(splitResult: LocationSplitResult) {
		this.locationSplitted.emit(splitResult);
		if (this.selectedPolygon) {
			this.selectPolygon(this.selectedPolygon, false);
		}
	}

	onLocationMerged(mergeResult: LocationMergeResult) {
		this.locationMerged.emit(mergeResult);
	}

	onMapBoxDraw(event: MapboxDraw.DrawCreateEvent) {
		this.locationDrawn.emit(this.makeMultiPolygon(event));
	}

	onMapBoxUpdated(event: MapboxDraw.DrawUpdateEvent) {
		this.locationUpdated.emit(this.makeMultiPolygon(event));
	}

	onMarkerClick(device: Device) {
		this.objectClick.emit([device, null]);
	}

	makeMultiPolygon(event: MapboxDraw.DrawCreateEvent | MapboxDraw.DrawUpdateEvent): Feature<MultiPolygon> {
		if (event.features[0].geometry.type === 'Polygon') {
			return multiPolygon([event.features[0].geometry.coordinates]);
		}
		if (event.features[0].geometry.type === 'MultiPolygon') {
			return event.features[0] as Feature<MultiPolygon>;
		}
		throw new Error('Invalid geometry type');
	}

	onLocationClick(features: Feature<MultiPolygon>[], location: Field) {
		if (this.fieldSelectionMode()) {
			if (this.selectedPolygon) {
				this.selectPolygon(this.selectedPolygon, false);
			}
			this.selectPolygon(features[0], true);
		}
		this.objectClick.emit([location, features[0]]);
	}

	clearNewMarker() {
		this.newMarker.set(undefined);
		this.newMarkerPosition.emit(undefined);
	}

	onMapClick($ev: MapMouseEvent) {
		if (this.setMarkerOnClick() && $ev.lngLat) {
			this.setAndEmit({ type: 'Point', coordinates: [$ev.lngLat.lng, $ev.lngLat.lat] });
		}
	}

	setAndEmit(newCoords: Point): void {
		this.newMarker.set(newCoords);
		this.newMarkerPosition.emit(this.newMarker());
	}

	getTemperature(device: Device) {
		const oneOfTemperatures =
			MeasurementTransformations.getMeasure(MeasurementName.SOIL_TEMPERATURE, device.latestMeasurements) ||
			MeasurementTransformations.getMeasure(MeasurementName.AIR_TEMPERATURE, device.latestMeasurements);
		return oneOfTemperatures ? MeasurementTransformations.getMeasureValueString(oneOfTemperatures) : '';
	}

	private getBrowserGeoLocation(newMarker?: Point): Promise<MultiPolygon[]> {
		const [lng, lat] = newMarker?.coordinates ?? [];
		if (lng && lat) {
			return Promise.resolve([this.createBoundsPolygon(lng, lat)]);
		}
		return new Promise((resolve) => {
			this.window.navigator.geolocation.getCurrentPosition(
				(pos) => resolve([this.createBoundsPolygon(pos.coords.longitude, pos.coords.latitude)]),
				() => resolve([this.createBoundsPolygon(DEFAULT_FITBOUND_LOCATION.lng, DEFAULT_FITBOUND_LOCATION.lat)]),
			);
		});
	}

	private createBoundsPolygon(lng: number, lat: number): MultiPolygon {
		return multiPolygon([polygon([Array(4).fill([lng, lat])]).geometry.coordinates]).geometry;
	}

	private selectPolygon(feature: Feature<MultiPolygon>, selected: boolean) {
		const polygonId = (feature as any).source;
		this.mapInstance?.setPaintProperty(
			polygonId.replace('polygon', 'polygon-fill'),
			'fill-color',
			selected ? SELECTED_POLYGON_COLOR : this.fillColor(),
		);
	}
}
