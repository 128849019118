import { BaseComponent } from '@agdir/core/angular';
import { ButtonComponent } from '@agdir/ui/button';
import { ChangeDetectionStrategy, Component, ElementRef, input, output, viewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltip } from '@angular/material/tooltip';

const fileUploadConfig = {
	maxFileSizeBytes: 25000000,
	allowedMimes: [
		'image/png',
		'image/jpg',
		'image/jpeg',
		'image/tiff',
		'application/pdf',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document', //.docx
		'application/msword', //.doc
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', //.xlsx
		'application/vnd.ms-excel', //.xls
	],
};

@Component({
	selector: 'agdir-file-upload',
	template: `
		<agdir-button icon="file_upload" color="custom" size="default" (click)="open()" [class.hidden]="doNotRender()" />
		<input type="file" class="hidden" #fileElem (change)="fileSelected($event)" />
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [ButtonComponent],
})
export class FileUploadComponent extends BaseComponent {
	random = Math.random();
	matTooltip = viewChild(MatTooltip);
	fileElem = viewChild<ElementRef<HTMLInputElement>>('fileElem');
	fileAdded = output<File>();
	doNotRender = input<boolean | 'false' | 'true'>(false);

	constructor(private _snackBar: MatSnackBar) {
		super();
	}

	open() {
		this.fileElem()?.nativeElement.click();
	}

	async fileSelected(e: Event) {
		const file = (<HTMLInputElement>e.target)?.files?.item(0);
		if (file) {
			if (file.size > fileUploadConfig.maxFileSizeBytes) {
				this._snackBar.open(`${file.name} is too large`, 'Dismiss', {
					panelClass: ['bg-red-500', 'text-blue-50'],
					duration: 3000,
				});
				return;
			}
			if (!fileUploadConfig.allowedMimes.includes(file.type)) {
				this._snackBar.open(`File type for ${file.name} is not supported`, 'Dismiss', {
					panelClass: ['bg-red-500', 'text-blue-50'],
					duration: 3000,
				});
				return;
			}
			this.fileAdded.emit(file);
		}
	}
}
