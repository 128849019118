@if (!hideToolbar() && setMarkerOnClick()) {
	<div class="flex flex-row space-x-3 p-3 border-l border-t border-r">
		@if (newMarker()) {
			<button (click)="clearNewMarker()" mat-button type="button">
				<agdir-icon icon="close" />
				<span class="hidden md:inline ml-2" transloco="modules.locationPage.locationMap.clearPosText"></span>
			</button>
		}
	</div>
}

@if (locationMap$ | async; as mapSettings) {
	<mgl-map
		(mapClick)="onMapClick($event)"
		(mapLoad)="onMapReady($event)"
		[attributionControl]="false"
		[fitBoundsOptions]="mapSettings.fitBoundsOptions"
		[fitBounds]="mapSettings.bounds"
		[maxZoom]="mapSettings.maxZoom"
		[minZoom]="mapSettings.minZoom"
		[style]="'mapbox://styles/mapbox/satellite-v9'"
		[zoom]="[mapSettings.zoom]"
		class="h-full w-full">
		@if (showLocateMeControl() && (myPosition$ | async); as myPosition) {
			<mgl-marker [lngLat]="[myPosition?.coordinates?.[0] || 0, myPosition?.coordinates?.[1] || 0]">
				<span class="flex h-2 w-2">
					<span class="animate-ping absolute h-full w-full rounded-full bg-red-500 opacity-85"></span>
					<span class="rounded-full h-2 w-2 bg-[red]"></span>
				</span>
			</mgl-marker>
		}
		@for (device of devices$ | async; track device) {
			@if (device?.geoPoint?.coordinates; as lngLat) {
				<mgl-marker [lngLat]="[lngLat[0] || 0, lngLat[1] || 0]">
					<!--					[label]="getTemperature(device)"-->
					<agdir-badge withDot [color]="device?.isConnected ? 'green' : 'red'" (click)="onMarkerClick(device)" />
				</mgl-marker>
			}
		}
		@if (newMarker() && newMarker()!.coordinates) {
			<mgl-marker [lngLat]="[newMarker()!.coordinates[0], newMarker()!.coordinates[1]]">
				<agdir-badge icon="location_on" />
			</mgl-marker>
		}
		@if (!editMode()) {
			@for (location of locations$ | async; track location) {
				<mgl-geojson-source [data]="location.geoJson" [id]="'polygon-' + location.id">
					<mgl-layer
						(layerClick)="onLocationClick($any($event.features), location)"
						[id]="'polygon-fill-' + location.id"
						[paint]="locationFillProps(location)"
						[source]="'polygon-' + location.id"
						type="fill"></mgl-layer>
					<mgl-layer
						[id]="'polygon-outline-' + location.id"
						[paint]="locationLineProps(location)"
						[source]="'polygon-' + location.id"
						type="line"></mgl-layer>
					@if (displayLocationNames()) {
						<mgl-layer
							[id]="'polygon-name-' + location.id"
							[layout]="{ 'text-field': location.name }"
							[source]="'polygon-' + location.id"
							type="symbol"></mgl-layer>
					}
				</mgl-geojson-source>
			}
		}
		@if (projectedPolygons()?.length) {
			<mgl-image id="delete-icon" url="assets/images/trash.png"></mgl-image>
		}
		@for (projected of projectedPolygons(); track projected.name()) {
			<mgl-geojson-source [data]="projected.feature()" [id]="'child-polygon-' + projected.name()">
				<mgl-layer
					[id]="'polygon-fill-' + projected.name()"
					[paint]="projectedFillPaint(projected)"
					[source]="'child-polygon-' + projected.name()"
					type="fill"></mgl-layer>
				<mgl-layer
					[id]="'polygon-outline-' + projected.name()"
					[paint]="projectedOutlinePaint(projected)"
					[source]="'child-polygon-' + projected.name()"
					type="line"></mgl-layer>
				<mgl-layer
					(layerClick)="projected.polygonClick.emit($event)"
					(layerTouchEnd)="projected.polygonClick.emit($event)"
					[id]="'polygon-name-' + projected.name()"
					[layout]="projectedSymbolPaint(projected)"
					[source]="'child-polygon-' + projected.name()"
					type="symbol"></mgl-layer>
			</mgl-geojson-source>
		}
		<ng-container *transloco="let t">
			@if (showGeocoderControl()) {
				<mgl-control [placeholder]="t('core.qrCodePage.searchText')" mglGeocoder position="top-left"></mgl-control>
			}
		</ng-container>
		@if (showFullscreen()) {
			<mgl-control agdirMapFullScreen position="top-right"></mgl-control>
		}
		@if (showSplitMergeTools()) {
			<mgl-control (locationSplitted)="onLocationSplitted($event)" agdirSplit position="top-right"></mgl-control>
		}
		@if (locations$ | async; as locations) {
			@if (showSplitMergeTools() && locations.length > 1) {
				<mgl-control (locationMerged)="onLocationMerged($event)" agdirMerge position="top-right"></mgl-control>
			}
		}
		@if (showDrawingTools() || editMode()) {
			<mgl-control
				(locationDrawn)="onMapBoxDraw($event)"
				(locationUpdated)="onMapBoxUpdated($event)"
				[editMode]="editMode()"
				agdirDraw
				position="top-right"></mgl-control>
		}
		@if (showLocateMeControl()) {
			<mgl-control (geolocate)="onGeoLocate($event)" agdirGeolocate position="top-right"></mgl-control>
		}
		@if (showDetectFieldsControl()) {
			<mgl-control
				(detecting)="loading.emit($event)"
				(fieldsDetected)="fieldsDetected.emit($event)"
				[minZoom]="14"
				agdirDetectFields
				position="top-right"></mgl-control>
		}
		@if (showZoningLayers()) {
			<mgl-control (showZones)="showZones($event)" agdirDigiZoning position="top-right"></mgl-control>
		}
		@if (hasAnyZone) {
			<mgl-control agdirCheckboxControl label="Show zones" position="top-right" (showZone)="locationZoneFilterChange($event)"></mgl-control>
			<!-- <mgl-control
      agdirCheckboxControl
      label="Show main fields"
      position="top-right"
    (showZone)="mainLocationFilterChange($event)"></mgl-control> -->
		}
		<ng-content></ng-content>
	</mgl-map>
}
